
<script setup>
  import { reactive, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import BaseInput from './BaseInput.vue';
  import sidemenuPage from './sidemenuPage.vue';
  import headerPage from './headerPage.vue';
  import useVuelidate from '@vuelidate/core';
  import { required  } from '@vuelidate/validators';
  import axios from 'axios';

  const event =  reactive({
    txtRoadtax: '',
    txtRTAmount: 0,
    txtGoodspermit: '',
    txtGPAmount: 0,
    txtPollutioncontrol: '',
    txtSafety: '',
    txtSAmount: 0,
    dtSExpiry: '',
    txtPCAmount: 0,
    dtRTExpiry: '',
    dtGPExpiry: '',
    dtPCExpiry: '',
    txtChasisno: '',
    name: '',
    isRoadtax: false,
    isGoodspermit: false,
    isPollution: false,
    isSafety: false
  });

  const rulesGoodspermit = computed(() => {
    return {
      dtGPExpiry: { required }
    }
  });

  const rulesRoadtax = computed(() => {
    return {
      dtRTExpiry: { required }
    }
  });

  const rulesPollcontrol = computed(() => {
    return {
      dtPCExpiry: { required }
    }
  });

  const rulesSafety = computed(() => {
    return {
      dtSExpiry: { required }
    }
  });

  const vGoodspermit$ = useVuelidate(rulesGoodspermit, event);
  const vRoadtax$ = useVuelidate(rulesRoadtax, event);
  const vPollcontrol$ = useVuelidate(rulesPollcontrol, event);
  const vSafety$ = useVuelidate(rulesSafety, event);

  const router = useRouter();
  const route = useRoute();

  const vehicles  = route.params.id



  onMounted(() => {
      const API_URL = 'vehicles/' + vehicles + '/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          const vehicleData = response.data;
          console.log(vehicleData)
          event.isRoadtax = vehicleData.cert_data.road_tax
          event.isGoodspermit = vehicleData.cert_data.goods_permit
          event.isPollution = vehicleData.cert_data.pollution_control
          event.isSafety = vehicleData.cert_data.safety
          event.rtoNumber = 'RTO No.' + vehicleData.registration_no
          // Road Tax
          if (vehicleData.cert_data.road_tax) {
            event.txtRoadtax  = vehicleData.road_tax_data.rt_cert_no
            event.txtRTAmount  = vehicleData.road_tax_data.amount
            event.dtRTExpiry  = vehicleData.road_tax_data.expiry_date_str
          }  else {
            event.txtRoadtax = ''
            event.txtRTAmount = 0
            event.dtRTExpiry = ''
          }
          // Goods Permit

          if (vehicleData.cert_data.goods_permit) {
            event.txtGoodspermit  = vehicleData.goods_permit_data.gp_cert_no
            event.txtGPAmount = vehicleData.goods_permit_data.amount
            event.dtGPExpiry = vehicleData.goods_permit_data.expiry_date_str
          }  else {
            event.txtGoodspermit = ''
            event.txtGPAmount = 0
            event.dtGPExpiry = ''
          }
          // Pollution Control Certificate

          if (vehicleData.cert_data.pollution_control ) {
            event.txtPollutioncontrol = vehicleData.pollution_control_data.pc_cert_no
            event.txtPCAmount = vehicleData.pollution_control_data.amount
            event.dtPCExpiry = vehicleData.pollution_control_data.expiry_date_str
          } else {
            event.txtPollutioncontrol = ''
            event.txtPCAmount = 0
            event.dtPCExpiry = ''
          }
          
          // Safety
          if (vehicleData.cert_data.safety ) {
            event.txtSafety = vehicleData.safety_data.safety_cert_no
            event.txtSAmount = vehicleData.safety_data.amount
            event.dtSExpiry = vehicleData.safety_data.expiry_date_str
          } else {
            event.txtSafety = ''
            event.txtSAmount = 0
            event.dtSExpiry = ''
          }
      }, (error) => {
          this.error = error.response.data.message;
          alert(this.error);
      })
  });

  const submitForm = async() => {
        let data = {'crossDomain': true}
        if(event.isSafety) {
          const resultSafety = await vSafety$.value.$validate();
          if(resultSafety) {
            data.vehicle_safety_data = {
                                        "safety_cert_no": event.txtSafety,
                                        "amount": event.txtSAmount,
                                        "expiry_date": event.dtSExpiry
                                    }
          } else {
            console.log("fields Required" + resultSafety)
            return;
          }
        }
        if(event.isRoadtax) {
          const resultRoadtax = await vRoadtax$.value.$validate();
          if(resultRoadtax) {
            data.vehicle_rt_data = {
                          "rt_cert_no": event.txtRoadtax,
                          "amount": event.txtRTAmount,
                          "expiry_date": event.dtRTExpiry
                      }
          } else {
            console.log("fields Required" + resultRoadtax)
            return;
          }
        }
        if(event.isGoodspermit) {
          const resultGoodspermit = await vGoodspermit$.value.$validate();
          if(resultGoodspermit) {
            data.vehicle_gp_data = {
                          "gp_cert_no": event.txtGoodspermit,
                          "amount": event.txtGPAmount,
                          "expiry_date": event.dtGPExpiry
                      }
          } else {
            console.log("fields Required" + resultGoodspermit)
            return;
          }
        }
        if(event.isPollution) {
          const resultPollution = await vPollcontrol$.value.$validate();
          if(resultPollution) {
            data.vehicle_pc_data = {
                          "pc_cert_no": event.txtPollutioncontrol,
                          "amount": event.txtPCAmount,
                          "expiry_date": event.dtPCExpiry  
                      }
          } else {
            console.log("fields Required" + resultPollution)
            return;
          }
        }
            axios.post('vehicles/?vehicle_id=' + vehicles, data,
                      { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
                .then(response => {
                    // this.btnDisable = false;
                    localStorage.setItem('vehicleId',response.data.vehicle_id)
                    const vehicleId = response.data.vehicle_id
                    router.push({ path: '/lists', params: { vehicleId } })
                    // this.$router.push({ path: '/lists', params: { vehicleId } })
                }).catch(error => {
                  console.log(error)
                    alert(error.response.data.message)
                    return
                })
    
  };

</script>
<template>
    <div>
        <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
            <div class="container-fluid">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add Road Tax, Good Permit, Pollution Control Details</li>
            </ol>
            </nav>
            <div class="card">
              <div class="card-body">
                <div class="container text-center">
                  <!-- Header  -->
                    <div class="row">
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">1</button>
                        <br/>
                        Vehicle
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">2</button>
                        <br/>
                        Insurance
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">3</button>
                        <br/>
                        FC Data
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-success rounded-circle">4</button>
                        <br/>
                        Misc. Details
                      </div>
                    </div>
                    </div>
                    <div class="container-fluid">
                    <div class="row">
                      <form @submit.prevent="submitForm">
                      <div class="col">
                        <hr/>
                        <p> Misc. Details</p>
                        <div class="alert alert-primary" role="alert">
                          {{ event.rtoNumber }}
                        </div>
                        <!-- Road Tax -->
                        <div class="card" style="width: auto;" v-if="event.isRoadtax">
                            <div class="card-body">
                                <h5 class="card-title">Road Tax</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                      <BaseInput  
                                        v-model="event.txtRoadtax"
                                        label="Certificate Number"
                                        type="text" />
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtRTAmount"
                                                  label="Amount"
                                                  type="number"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.dtRTExpiry"
                                                  label="Expiry Date"
                                                  type="date"/>
                                      <span v-for="error in vRoadtax$.dtRTExpiry.$errors" :key="error.$uid" class="error">
                                      {{ error.$message }}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <!-- Road Tax -->
                        <div class="card" style="width: auto;"  v-if="event.isGoodspermit">
                            <div class="card-body">
                                <h5 class="card-title">Good Permit</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtGoodspermit"
                                                  label="Certificate Number"
                                                  type="text"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtGPAmount"
                                                  label="Amount"
                                                  type="number"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.dtGPExpiry"
                                                  label="Expiry Date"
                                                  type="date"/>
                                      <span v-for="error in vGoodspermit$.dtGPExpiry.$errors" :key="error.$uid" class="error">
                                      {{ error.$message }}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Pollution Control -->
                        <div class="card" style="width: auto;"  v-if="event.isPollution">
                            <div class="card-body">
                                <h5 class="card-title">Pollution Control Certificate</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtPollutioncontrol"
                                                  label="Certificate Number"
                                                  type="text"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtPCAmount"
                                                  label="Amount"
                                                  type="number"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.dtPCExpiry"
                                                  label="Expiry Date"
                                                  type="date"/>
                                      <span v-for="error in vPollcontrol$.dtPCExpiry.$errors" :key="error.$uid" class="error">
                                      {{ error.$message }}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Safety Certificate -->
                        <div class="card" style="width: auto;"  v-if="event.isSafety">
                            <div class="card-body">
                                <h5 class="card-title">Safety Certificate</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtSafety"
                                                  label="Certificate Number"
                                                  type="text"/>
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.txtSAmount"
                                                  label="Amount"
                                                  type="number"/>
                                      
                                    </div>
                                    <div class="col-md-4">
                                      <BaseInput  v-model="event.dtSExpiry"
                                                  label="Expiry Date"
                                                  type="date"/>
                                      <span v-for="error in vSafety$.dtSExpiry.$errors" :key="error.$uid" class="error">
                                        {{ error.$message }}
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                          <div class="col-md-10">
                            <br/>
                            <button type="button" class="btn text-light secondary-bg" @click="router.go(-1)"><i class="bi bi-caret-left-square"></i> Previous</button>
                          </div>
                          <div class="col-md-2">
                            <br/>
                            <button  type="submit" class="btn text-light main-bg" dark><i class="bi bi-check-square"></i> Finish </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    </div>
                  </div>
              </div>
            </div>
         </div>
        </div>
        </div>
        </div>
        
    </div>
</template>

    <style>
      .error {
        color: #ff0062;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
      }
    </style>
