<template>
    <div>
            <div class="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center">
                <ul class="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center align-items-center">
                    <li class="nav-item">
                        <a href="#" class="nav-link py-3 px-2" title="" @click="$router.push('/dashboard')" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Analytics">
                            <i class="bi-reception-4 fs-3"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="nav-link py-3 px-2" title="" @click="$router.push('/lists')" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Add New Vehicle">
                            <i class="bi bi-journal fs-3" ></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="nav-link py-3 px-2" title="" @click="$router.push('/vehicle')" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Vehicle List">
                            <i class="bi bi-plus-square fs-3"></i>
                        </a>
                    </li>
                </ul>
                
            </div>
        </div>
</template>

<script>
    export default {
        name: 'sidemenuPage',
        data() {
            return {

            }
        },
        methods: {
           
        }
    }
</script>
