<script setup>
  import { reactive, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import sidemenuPage from './sidemenuPage.vue';
  import headerPage from './headerPage.vue';
  import axios from 'axios';

  const event =  reactive({
    loading: false,
    btnDisable: false,
    modalShow: false,
    data: [],
    insData: [],
    fcData: [],
    rtData: [],
    pcData: [],
    gpData: [],
    showModal: false,
  });
  const route = useRoute();

  const vehicles  = route.params.id

  onMounted(() => {
      event.loading = true;
      const API_URL = 'vehicles/' + vehicles + '/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          event.loading = false;
          event.data = response.data;
          event.insData = response.data.insurance_data
          event.fcData = response.data.fitness_data
          event.rtData = response.data.road_tax_data
          event.pcData = response.data.pollution_control_data
          event.gpData = response.data.goods_permit_data
          event.safetyData = response.data.safety_data
          console.log(event.data)
      }, (error) => {
          event.loading = false;
          this.error = error.response.data.message;
          alert(this.error);
      })
  });

  
</script>

<template>
    <div>
      <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
            <div class="container-fluid">
          <modal :show="showModal" @close="showModal = false">
            <template #header>
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </template>
          </modal>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Vehicle Detail</li>
            </ol>
            </nav>
            <div class="card">
                <div class="card-body">
                    <div class="row card-title">
                        <div class="col-md-4 col-sm-12">
                            <h5>{{event.data.vehicle_group}}</h5>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-action active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Fuel Type :  {{event.data.fuel_type}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12" style="font-weight: 300; color: #fff;">
                                      <span> <h5>RTO No. : {{event.data.registration_no}}</h5></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span> Engine No. :  {{event.data.engine_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span> Chasis No. : {{event.data.chasis_no}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span> Make :  {{event.data.make}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span> Model : {{event.data.model}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span> Year Manufactured :  {{event.data.year_of_manufatruring}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br/>

                        <!-- Insurance details -->

                        <div class="row">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Insurance Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-profile-list1" data-bs-toggle="list" href="#list-profile1" role="tab" aria-controls="list-profile1">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span> Insurance Name. :  {{event.insData.insurance_company}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span> Policy No. : {{event.insData.policy_no}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Premium :  {{event.insData.premium}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Date : {{event.insData.expiry_date}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Agent Name :  {{event.insData.agent_name}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Phone No :  {{event.insData.agent_phone}}</span>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <!-- Fitness Certificate details -->

                        <div class="row">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>FC Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Certificate No. :  {{event.fcData.fc_cert_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Amount : {{event.fcData.fc_amount}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Expired Date :  {{event.fcData.expiry_date}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <!-- Road Tax details -->

                        <div class="row" v-if="event.rtData">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Road Tax Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Certificate No. :  {{event.rtData.rt_cert_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Amount : {{event.rtData.amount}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Expired Date :  {{event.rtData.expiry_date}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <!-- Pollution Control details -->

                        <div class="row" v-if="event.pcData">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Pollution Control Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Certificate No. :  {{event.pcData.pc_cert_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Amount : {{event.pcData.amount}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Expired Date :  {{event.pcData.expiry_date}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <!-- Good Permit details -->

                        <div class="row" v-if="event.gpData">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Goods Permit Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Certificate No. :  {{event.gpData.gp_cert_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Amount : {{event.gpData.amount}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Expired Date :  {{event.gpData.expiry_date}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>

                        <!-- Safety details -->

                        <div class="row" v-if="event.safetyData">
                          <div class="col-12">
                            <div class="list-group" id="list-tab" role="tablist">
                              <a class="list-group-item list-group-item-secondary" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="list-home">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Safety Detail</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span></span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Certificate No. :  {{event.safetyData.safety_cert_no}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                      <span>Amount : {{event.safetyData.amount}}</span>
                                  </div>
                                </div>
                              </a>
                              <a class="list-group-item list-group-item-action" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">
                                <div class="row">
                                  <div class="col-md-6 col-sm-12">
                                      <span>Expired Date :  {{event.safetyData.expiry_date}}</span>
                                  </div>
                                  <div class="col-md-6 col-sm-12">
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                </div>
              
            </div>
        </div>
        </div>
        </div>
        </div>
    </div>
</template>
