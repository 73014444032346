<template>
  <div>
    <nav
      class="navbar navbar-expand-lg" style="background-color: #e3f2fd; color: #fff"
    >
      <div class="container-fluid">
        <img
            src="/assets/images/logo-new.png"
            alt=""
            height="40"
            class="d-inline-block align-text-top"
          />
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li
              class="nav-item"
              v-for="link in links"
              :key="link.id"
              @click="menuActive(link.id)"
              :ref="'nav-item_' + link.id"
            >
              <a class="nav-link">
                <i :class="link.icon"></i>
                <span>&nbsp;&nbsp;{{ link.text }}</span>
              </a>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
              <a class="nav-link">
                <i class="bi bi-person"></i>
                <span>&nbsp;&nbsp;{{userLogged}}</span>
              </a>
            </li>
            <li class="nav-item" v-on:click="logout()">
              <a class="nav-link">
                <i class="bi bi-power"></i>
                <span>&nbsp;&nbsp;Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "headerPage",
  data() {
    return {
      userLogged: '',
      links: [
        { id: 1, icon: "bi bi-reception-4", text: "Analytics" },
        { id: 2, icon: "bi bi-journal", text: "Lists" },
        { id: 3, icon: "bi bi-plus-square", text: "Vehicle" },
      ],
    };
  },
  mounted: function () {
    this.getUsers()
  },
  methods: {
    getUsers() {
      const userName = (localStorage.username)
      this.userLogged = userName.charAt(0).toUpperCase() + userName.slice(1)
      return
    },
    menuActive(id) {
      if (id == 1) {
        this.$router.push("/dashboard");
      }
      if (id == 2) {
        this.$router.push("/lists");
      }
      if (id == 3) {
        this.$router.push("/vehicle");
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>

<style>
  .invisible {
    visibility: hidden;
  }
.navbar {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #0277bd;
  background: linear-gradient(to left, #0277bd, #2979ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  height: 70px;
  padding: 1em;
}
.nav-link {
  color: #fff;
}

.navbar-nav .nav-link {
  color: #fff;
}

.navbar .nav-item:hover .nav-link {
  color: #64ffda;
  border-bottom: 3px solid #64ffda;
}

.navbar-nav .nav-link .active {
  color: #03dac5;
  border-bottom: 3px solid #03dac5;
}

.navbar .navbar-brand {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #0277bd;
  background: linear-gradient(to left, #0277bd, #2979ff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  height: 70px;
  padding: 1em;
}
.navbar .nav-item {
  padding: 5px 15px;
}
.navbar .nav-link {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  color: #fff;
}
</style>

<!-- <style>
/* @import url(https://fonts.googleapis.com/css?family=Raleway); */
h2 {
  vertical-align: center;
  text-align: center;
}


.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #00BAF0;
  background: linear-gradient(to left, #0277BD, #2979FF);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #FFF;
  height: 70px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  padding-top: 1.2rem;
  font-size: 16px;
  font-weight: bold;
}

.mnu_icons {
  margin: 0 1rem;
  padding-top: 1.2rem;
  font-size: 16px;
  font-weight: bold;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 700px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
}

</style> -->

<!-- <style>
  .white, .white a {
    color: #fff;
  }

</style> -->
