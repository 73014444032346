import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.css"
import Axios from 'axios'

const app = createApp(App)
Axios.defaults.baseURL = 'https://veta.vilsonsgroup.com/api/'
app.config.globalProperties.$hostname = 'https://veta.vilsonsgroup.com/api/'
app.config.globalProperties.$basename = 'https://veta.vilsonsgroup.com'
app.provide('urlName', 'https://veta.vilsonsgroup.com/api/')
app.use(router)
app.mount('#app')
import "bootstrap/dist/js/bootstrap.js"
