<script setup>
  import { reactive, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import BaseInput from './BaseInput.vue';
  import sidemenuPage from './sidemenuPage.vue';
  import headerPage from './headerPage.vue';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength  } from '@vuelidate/validators';
  import axios from 'axios';
  import Modal from './Modal.vue'

  const event =  reactive({
    cboInsuranceName: '',
    insuranceList: [],
    txtPolicyNo: '',
    txtAgentName: '',
    txtPhone: '',
    txtAmount: '',
    dtExpiry: '',
    rtoNumber: '',
    modalShow: false
  });

  const rules = computed(() => {
    return {
      cboInsuranceName: { required },
      txtPolicyNo: { required, minLength: minLength(4) },
      txtAmount: { required, minLength: minLength(1) },
      dtExpiry: { required }
    }
  });

  const v$ = useVuelidate(rules, event);

  const router = useRouter();
  const route = useRoute();

  const vehicles  = route.params.id

  onMounted(() => {
      event.modalShow = true;
      const API_URL = 'combos/insurance/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          event.modalShow = false;
          event.data = response.data;
          event.insuranceList = event.data.data
      }, (error) => {
          event.modalShow = false;
          this.error = error.response.data.message;
          alert(this.error);
      })
  });
  onMounted(() => {
      event.modalShow = true
      const API_URL = 'vehicles/' + vehicles + '/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          event.modalShow = false
          const vehicleData = response.data;
          event.rtoNumber = 'RTO No.' + vehicleData.registration_no
          event.cboInsuranceName = vehicleData.insurance_data.insurance_company_id
          event.txtPolicyNo = vehicleData.insurance_data.policy_no
          event.txtAgentName = vehicleData.insurance_data.agent_name
          event.txtPhone = vehicleData.insurance_data.agent_phone
          event.txtAmount = vehicleData.insurance_data.premium
          // const expDate = new Date(vehicleData.insurance_data.expiry_date)
          // event.dtExpiry = expDate.getFullYear() + "-" + (expDate.getMonth()+1) + "-" + expDate.getDate()
          event.dtExpiry = vehicleData.insurance_data.expiry_date_str
      }, (error) => {
          event.modalShow = false
          this.error = error.response.data.message;
          alert(this.error);
      })
  });

  const submitForm = async() => {
    const result = await v$.value.$validate();
    event.modalShow = true
    if(result) {
      const data = {'crossDomain': true,
                      "vehicle_insurance_data": {
                        "insurance_company_id": event.cboInsuranceName,
                        "policy_no": event.txtPolicyNo,
                        "agent_name": event.txtAgentName,
                        "agent_phone": event.txtPhone,
                        "premium": event.txtAmount,
                        "expiry_date": event.dtExpiry
                      }
                    }
            axios.post('vehicles/?vehicle_id=' + vehicles , data,
                      { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
                .then(response => {
                    event.modalShow = false
                    // this.btnDisable = false;
                    localStorage.setItem('vehicleId',response.data.vehicle_id)
                    router.push(`/fitnesscertificate/${vehicles}`)
                }).catch(error => {
                    event.modalShow = false
                    console.log(error)
                    alert('Error' + error.message)
                    return
                })
    } else {
      console.log("fields Required" + result)
    }
  };
</script>

<template>
    <div>
        <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
            <div class="container-fluid">
              <modal :show="modalShow" @close="modalShow = false">
            <template #header>
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </template>
          </modal>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add Insurance Detail</li>
            </ol>
            </nav>
            <div class="card">
              <div class="card-body">
                <div class="container text-center">
                  <!-- Header  -->
                    <div class="row">
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">1</button>
                        <br/>
                        Vehicle
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-success rounded-circle">2</button>
                        <br/>
                        Insurance
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">3</button>
                        <br/>
                        FC Data
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">4</button>
                        <br/>
                        Misc. Details
                      </div>
                    </div>
                    </div>
                    <div class="container-fluid">
                    <div class="row">
                      <div class="col">

                      <form @submit.prevent="submitForm">
                        <hr/>
                        <p> Insurance Details</p>
                        <div class="alert alert-primary" role="alert">
                          {{ event.rtoNumber }}
                        </div>
                        <!-- Insurance Details -->
                        <div class="row">
                          <div class="col-md-6">
                              <label for="inputPassword4" class="form-label">Insurance Name</label>
                              <select class="form-select" v-model="event.cboInsuranceName" aria-label="Default select example">
                                <option selected>Select Insurance Company</option>
                                <option v-for="ins, index in event.insuranceList" :key="index" :value="ins.id">{{ins.label}}</option>
                              </select> 
                              <span v-for="error in v$.cboInsuranceName.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtPolicyNo"
                                label="Policy No."
                                type="text" />
                              <span v-for="error in v$.txtPolicyNo.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtAgentName"
                                label="Agent Name"
                                type="text" />
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtPhone"
                                label="Phone No."
                                type="number" />
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtAmount"
                                label="Amount"
                                type="number" />
                              <span v-for="error in v$.txtAmount.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.dtExpiry"
                                label="Expiry Date"
                                type="date" />
                              <span v-for="error in v$.dtExpiry.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-10">
                            <br/>
                            <button type="button" class="btn text-light secondary-bg" @click="router.go(-1)"><i class="bi bi-caret-left-square"></i> Previous</button>
                          </div>
                          <div class="col-md-2">
                            <br/>
                            <button type="submit"   class="btn text-light main-bg" dark><i class="bi bi-caret-right-square"></i> Save & Continue</button>
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
         </div>
        </div>
        </div>
        </div>
        
    </div>
</template>
