<template>
  <Transition name="modal">
        <div v-if="showModal" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container-one">
              <div class="modal-body">
                <slot name="body">
                  <button type="button" class="btn-close" aria-label="Close" v-on:click="closeCall()"></button>
                  <img v-bind:src="imagePath"/>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </Transition>
  <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
      <div class="container-fluid">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Analytics</li>
            </ol>
            </nav>
            <canvas id="myChart"></canvas>

            <div class="card">
              <div class="card-body">
                <div class="container text-center">
                  <!-- Header  -->
                  <div class="card">
                    <div class="card-header">
                      Equipment Details
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3 col-sm-12" v-for="(item, index) in this.data.total_count" :key="index">
                          <div class="card border-primary mb-3" style="max-width: 18rem;">
                              <div class="card-header">
                                {{item.name}}
                              </div>
                              <div class="card-body text-success">
                                {{item.count}}
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header">
                      Expired
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3 col-sm-12" v-for="(item, index) in this.data.total_expired" :key="index">
                          <div class="card border-info mb-3" style="max-width: 18rem;">
                              <div class="card-header">
                                {{item.name}}
                              </div>
                              <div class="card-body text-success">
                                {{item.count}}
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="card">
                    <div class="card-header">
                      Not Updated
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3 col-sm-12" v-for="(item, index) in this.data.total_not_found" :key="index">
                          <div class="card border-primary mb-3" style="max-width: 18rem;">
                              <div class="card-header">
                                {{item.name}}
                              </div>
                              <div class="card-body text-success">
                                {{item.count}}
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  </div>
              </div>
            </div>
        </div>
        </div>
        </div>
        </div>
</template>
<script>
    import headerPage from './headerPage.vue';
    import sidemenuPage from './sidemenuPage.vue';
    import axios from 'axios';
 
    export default {
      
      name: "dashboardPage",
      data() {
        return {
          message: 'Analytics',
          data: [],
          showModal: false,
          imagePath: '',

        }
      },
      mounted(){
          this.getData();
      },
      components: {
        headerPage,
        sidemenuPage
    },
    methods: {
      
        closeCall() {
          this.showModal = false;
        },
        getData: function () {
            this.loading = true;
            this.API_URL = this.$hostname + "analytics/"
            axios.get(this.API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
            .then((response) => {
                this.loading = false;
                if(response.data.is_image === true) {
                  this.showModal = true;
                  this.imagePath = response.data.image_url
                  console.log(response.data)
                  this.data = response.data;
                }
                console.log(response.data)
                this.data = response.data;
            }, (error) => {
                this.loading = false;
                this.showModal = false;
                this.error = error.response.data.message;
                alert(this.error);
            })
        },
        
      }
    };
    </script>

    <style>
      .error {
        color: #ff0062;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
      }
      .modal-mask {
      position: fixed;
      /* z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; */
      /* background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease; */
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
      width: 600px;
    }
    
    .modal-container-one {
      width: 400px;
      opacity: 1;
      margin: 0px auto;
      padding: 2px 3px;
      /* background: #000; */
      border-radius: 2px;
      /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33); */
      transition: all 0.3s ease;
    }

    
    </style>
