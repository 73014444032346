<template>
    <div>
        <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
            <div class="container-fluid">
          <modal :show="showModal" @close="showModal = false">
            <template #header>
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </template>
          </modal>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Vehicle Detail</li>
            </ol>
            </nav>
            <div class="card">
              <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                  <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </symbol>
                  <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                  </symbol>
                  <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </symbol>
                </svg>
              <div class="card-body">
                <div class="container text-center">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="input-group">
                        <span class="input-group-text">Search By</span>
                        <input type="text" aria-label="" placeholder="RTO Number, Chasis No." v-model="txtSearch" class="form-control">
                        <button  class="btn text-light btn-info" v-bind:disabled="btnDisable" dark @click="getData">Search
                        </button>
                        <button  class="btn text-light btn-warning" v-bind:disabled="btnDisable" dark @click="resetgetData">Clear
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <select class="form-select" v-model="perPage" @change="getData()" aria-label="Default select example">
                        <option selected>Select Total Records to Display</option>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item" v-for="(no, index) in rows" :key="index">
                            <a class="page-link" href="#" @click="getcurrentPage(no)">{{no}}</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <br />
                  
                    <div class="table-responsive">
                        <table class="table table-hover table-striped" >
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>View</th>
                              <th>Edit</th>
                              <th>FC</th>
                              <th>Ins.</th>
                              <th>R.Tax</th>
                              <th>G.P</th>
                              <th>P.C</th>
                              <th>Group</th>
                              <th>RTO Number</th>
                              <th>Engine No</th>
                              <th>Chasis No</th>
                              <th>Fuel Type</th>
                              <th>Make</th>
                              <th>Model</th>
                              <th>Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(items, index) in data" :key="index">
                              <td v-text="items.s_no"></td>
                              <td>
                                  <i class="bi bi-journal-text" @click="getView(items.id)"></i>
                              </td>
                              <td>
                                <a href="#">
                                  <i class="bi bi-pencil" @click="getEdit(items.id)"></i>
                                </a>
                              </td>
                              <!-- 1 - All Ok
                              0 - Not Updated
                              2 - Updated but Expired -->
                              <td v-if="items.fc_status == 1" data-bs-toggle="tooltip" data-bs-placement="right" title="Updated">
                                  <i class="bi bi-check-square icon-green icon-large" ></i>
                              </td>
                              <td v-else-if="items.fc_status == 0" data-bs-toggle="tooltip" data-bs-placement="right" title="Not Updated">
                                <i class="bi bi-x-square icon-orange icon-large"></i>
                              </td>
                              <td v-else-if="items.fc_status == 2" data-bs-toggle="tooltip" data-bs-placement="right" title="Expired">
                                <i class="bi bi-exclamation-square icon-red icon-large"></i>
                              </td>
                              <td v-if="items.insurance_status == 1" data-bs-toggle="tooltip" data-bs-placement="right" title="Updated">
                                  <i class="bi bi-check-square icon-green icon-large"></i>
                                  <!-- <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg> -->
                              </td>
                              <td v-else-if="items.insurance_status == 0" data-bs-toggle="tooltip" data-bs-placement="right" title="Not Updated">
                                <i class="bi bi-x-square icon-orange icon-large"></i>
                                <!-- <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"/></svg> -->
                              </td>
                              <td v-else-if="items.insurance_status == 2" data-bs-toggle="tooltip" data-bs-placement="right" title="Expired">
                                <i class="bi bi-exclamation-square icon-red icon-large"></i>
                                <!-- <svg data-bs-toggle="tooltip" data-bs-placement="top" title="Expired" class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg> -->
                              </td>
                              <td v-if="items.road_tax_status == 1" data-bs-toggle="tooltip" data-bs-placement="right" title="Updated">
                                  <i class="bi bi-check-square icon-green icon-large" ></i>
                              </td>
                              <td v-else-if="items.road_tax_status == 0" data-bs-toggle="tooltip" data-bs-placement="right" title="Not Updated">
                                <i class="bi bi-x-square icon-orange icon-large"></i>
                              </td>
                              <td v-else-if="items.road_tax_status == 2" data-bs-toggle="tooltip" data-bs-placement="right" title="Expired">
                                <i class="bi bi-exclamation-square icon-red icon-large"></i>
                              </td>
                              <td v-if="items.goods_permit_status == 1" data-bs-toggle="tooltip" data-bs-placement="right" title="Updated">
                                  <i class="bi bi-check-square icon-green icon-large" ></i>
                              </td>
                              <td v-else-if="items.goods_permit_status == 0" data-bs-toggle="tooltip" data-bs-placement="right" title="Not Updated">
                                <i class="bi bi-x-square icon-orange icon-large"></i>
                              </td>
                              <td v-else-if="items.goods_permit_status == 2" data-bs-toggle="tooltip" data-bs-placement="right" title="Expired">
                                <i class="bi bi-exclamation-square icon-red icon-large"></i>
                              </td><td v-if="items.pollution_control_status == 1" data-bs-toggle="tooltip" data-bs-placement="right" title="Updated">
                                  <i class="bi bi-check-square icon-green icon-large" ></i>
                              </td>
                              <td v-else-if="items.pollution_control_status == 0" data-bs-toggle="tooltip" data-bs-placement="right" title="Not Updated">
                                <i class="bi bi-x-square icon-orange icon-large"></i>
                              </td>
                              <td v-else-if="items.pollution_control_status == 2" data-bs-toggle="tooltip" data-bs-placement="right" title="Expired">
                                <i class="bi bi-exclamation-square icon-red icon-large"></i>
                              </td>
                              <td v-text="items.vehicle_group"></td>
                              <td v-text="items.registration_no"></td>
                              <td v-text="items.engine_no"></td>
                              <td v-text="items.chasis_no"></td>
                              <td v-text="items.fuel_type"></td>
                              <td v-text="items.make"></td>
                              <td v-text="items.model"></td>
                              <td v-text="items.year_of_manufatruring"></td>

                            </tr>
                          </tbody>
                        </table> 
                        <p style="font-size: 14px; color: #ff0000;">{{this.txtMessage}}</p>
                    </div>
            </div>
         </div>
        </div>
        </div>
        </div>
        </div>
        </div>
    </div>
</template>
<script>
    import sidemenuPage from './sidemenuPage.vue';
    import headerPage from './headerPage.vue';
    import axios from 'axios';
    import Modal from './Modal.vue'
    export default {
      name: "listPage",
      data() {
        return {
          loading: false,
          btnDisable: false,
          modalShow: false,
          txtSearch: '',
          txtMessage: '',
          data: [],
          currentPage: 1,
          showModal: false,
          perPage: 10,
          rows: 1,
          get itemsForList() {
            return this.data.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage,
            );
          }
        }
      },
      mounted:function(){
          this.getData()
      },
      components: {
        sidemenuPage,
        headerPage,
        Modal
      },
      methods: {
        getEdit(id) {
          this.$router.push(`/insurance/${id}`)
          // router.push(`/insurance/${vehicleId}`)
        },
        getView(id) {
          this.$router.push(`/vehicle-view/${id}`)
        },
        getcurrentPage(no) {
          this.currentPage = no;
          this.getData()
        },
        resetgetData: function() {
          this.txtSearch = '';
          this.getData()
        },
        getData: function () {
            this.loading = true;
            this.showModal = true;
            this.txtMessage = '';
            this.API_URL = this.$hostname + "vehicles/?per_page=" + this.perPage + "&page=" + this.currentPage + "&search_by=" + this.txtSearch
            axios.get(this.API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
            .then((response) => {
                if(response.data.total_records == 0) {
                  this.txtMessage = 'No Details Found'
                }
                this.loading = false;
                this.showModal = false;
                const newValue =  Math.round(response.data.total_records / this.perPage)
                this.rows = newValue
                this.data = response.data.data;
            }, (error) => {
                this.loading = false;
                this.showModal = false;
                this.error = error.response.data.message;
                alert(this.error);
            })
        },
        
      }
    };
    </script>

    <style>
      .error {
        color: #ff0062;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
      }
      .icon-green {
        color: green;
      }
      .icon-red {
        color: red;
      }
      .icon-orange {
        color: orange;
      }
      .icon-large {
        font-size: 20px;
      }
    </style>
