<template>
    <div >
      <div class="progress" v-if="loading">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
      </div>
      <div class="container">
        <div v-if="seen">
            <div class="alert alert-danger" role="alert">
                {{error_message}}
              </div>
        </div>
    <div class="row justify-content-center mt-5">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="card shadow">
          <div class="card-title text-center border-bottom">
            <img src="assets/images/logo.png"/>
          </div>
          <div class="card-body">
              <div class="mb-4">
                <label for="username" class="form-label">Username</label>
                <input v-model="txtusername" label="User Name"  required autofocus  class="form-control"/>
              </div>
              <div class="mb-4">
                <label for="password" class="form-label">Password</label>
                <input v-model="txtpassword" label="Password" type="password" required  class="form-control" />
              </div>
              <div class="d-grid">
                <button  class="btn text-light main-bg" v-bind:disabled="btnDisable" dark @click="auth">Login</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</template>
<script>
  import axios from 'axios';
    export default {
      name: "loginPage",
      data() {
        return {
            txtusername: "",
            txtpassword: "",
            loading: false,
            btnDisable: false,
            error_message: "",
            seen: false,
            modalOpen: false
        }
      },
    computed: {
    },
    methods: {
      auth() {
           if (!this.txtusername) {
              this.seen = true
              this.error_message = "Username is required"
              return
            } else  if(!this.txtpassword ) {
              this.seen = true
              this.error_message = "Password is required"
              return
            }
        this.loading = true;
        this.btnDisable = true;
        const user_name = this.txtusername
        const password = this.txtpassword
        const data = {crossDomain: true, 'user_name': user_name, 'password': password}
        const API_URL = this.$hostname + 'login/'
        axios.post(API_URL, data, {'Access-Control-Allow-Origin': this.$basename, 'content-type': 'application/json'})
        .then(response => {
                if (response.status === 204) {
                    localStorage.clear();
                    alert('Check Login Details');
                    this.loading = false;
                    this.btnDisable = false;
                    return;
                }
                console.log(response)
                console.log(response.data)
                const token = response.data.token
                const userName = response.data.user_name
                const companyCode = response.data.company_code
                const role = response.data.role_name
                const companyName = response.data.company_name
                const user_id = response.data.user_id
                localStorage.setItem('jwt', token)
                localStorage.setItem('username', userName)
                localStorage.setItem('user_dept', companyCode)
                localStorage.setItem('role', role)
                localStorage.setItem('c_id', companyName)
                localStorage.setItem('u_id', user_id)
                this.loading = false;
                this.btnDisable = false;
                this.$router.push('/dashboard');
            })
            .catch(error => {
              console.log(error)
                localStorage.clear();
                this.btnDisable = false;
                this.loading = false;
                this.seen = true;
                this.error_message = error.response.data.message;
                return
            })
      },
      clear() {
          this.txtpassword = "",
          this.txtusername = ""

      }
    }
    };
    </script>

