import { createWebHistory, createRouter } from "vue-router";
import loginPage from "@/components/loginPage.vue";
import dashboardPage from "@/components/dashboardPage.vue";
import vehiclePage from "@/components/vehiclePage.vue";
import insurancePage from "@/components/insurancePage.vue";
import fcPage from "@/components/fcPage.vue";
import roadtaxPage from "@/components/roadtaxPage.vue";
import listPage from "@/components/listPage.vue";
import vehicleView from "@/components/vehicleView.vue"

const routes = [
  {path: '/',  name: 'Login',  component: loginPage },
  {path: '/dashboard',  name: 'Analytics',  component: dashboardPage },
  {path: '/vehicle',  name: 'Vehicle',  component: vehiclePage },
  {path: '/insurance/:id',  name: 'Insurance',  component: insurancePage },
  {path: '/fitnesscertificate/:id',  name: 'fitnesscertificate',  component: fcPage },
  {path: '/misctax/:id',  name: 'misctax',  component: roadtaxPage },
  {path: '/lists',  name: 'lists',  component: listPage },
  {path: '/vehicle-view/:id',  name: 'vehicleView',  component: vehicleView }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
