<script setup>
  import { reactive, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import BaseInput from './BaseInput.vue';
  import sidemenuPage from './sidemenuPage.vue';
  import headerPage from './headerPage.vue';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength  } from '@vuelidate/validators';
  import axios from 'axios';

  const event =  reactive({
    txtFC: '',
    txtAmount: '',
    dtExpiry: '',
    rtoNumber: ''
  });

  const rules = computed(() => {
    return {
      txtFC: { required, minLength: minLength(5) },
      txtAmount: { required, minLength: minLength(1) },
      dtExpiry: { required }
    }
  });

  const v$ = useVuelidate(rules, event);

  const router = useRouter();
  const route = useRoute();

  const vehicles  = route.params.id


  onMounted(() => {
      const API_URL = 'vehicles/' + vehicles + '/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          const vehicleData = response.data;
          event.rtoNumber = 'RTO No.' + vehicleData.registration_no
          event.txtFC = vehicleData.fitness_data.fc_cert_no
          event.txtAmount = vehicleData.fitness_data.fc_amount
          // const expDate = new Date(vehicleData.fitness_data.expiry_date)
          // event.dtExpiry = expDate.getFullYear() + "-" + (expDate.getMonth()+1) + "-" + expDate.getDate()
          event.dtExpiry = vehicleData.fitness_data.expiry_date_str
      }, (error) => {
          this.error = error.response.data.message;
          alert(this.error);
      })
  });

  const submitForm = async() => {
    const result = await v$.value.$validate();
    if(result) {
      const data = {'crossDomain': true,
                      "vehicle_fc_data": {
                        "fc_cert_no": event.txtFC,
                        "fc_amount": event.txtAmount,
                        "expiry_date": event.dtExpiry
                      }
                    }
            axios.post('vehicles/?vehicle_id=' + vehicles, data,
                      { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
                .then(response => {
                    console.log(response.data)
                    // this.btnDisable = false;
                    localStorage.setItem('vehicleId',response.data.vehicle_id)
                    router.push(`/misctax/${vehicles}`)
                }).catch(error => {
                    console.log(error)
                    alert('Error' + error.message)
                    return
                })
    } else {
      console.log("fields Required" + result)
    }
  };
</script>

<template>
    <div>
        <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
                 <!-- content -->
            <div class="container-fluid">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add Fitness Certificate</li>
            </ol>
            </nav>
            <div class="card">
              <div class="card-body">
                <div class="container text-center">
                  <!-- Header  -->
                    <div class="row">
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">1</button>
                        <br/>
                        Vehicle
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-primary rounded-circle">2</button>
                        <br/>
                        Insurance
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-success rounded-circle">3</button>
                        <br/>
                        FC Data
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">4</button>
                        <br/>
                        Misc. Details
                      </div>
                    </div>
                    </div>
                    <div class="container-fluid">
                    <div class="row">
                      <div class="col">
                        <hr/>
                        <p>Fitness Details</p>
                        <div class="alert alert-primary" role="alert">
                          {{ event.rtoNumber }}
                        </div>
                        <!-- Insurance Details -->
                        <form @submit.prevent="submitForm">
                        <div class="row">
                          <div class="col-md-12">
                            <BaseInput  
                                v-model="event.txtFC"
                                label="Fitness Certificate"
                                type="text" />
                              <span v-for="error in v$.txtFC.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtAmount"
                                label="Amount"
                                type="number" />
                              <span v-for="error in v$.txtAmount.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.dtExpiry"
                                label="Expiry Date"
                                type="date" />
                              <span v-for="error in v$.dtExpiry.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-10">
                            <br/>
                            <button type="button" class="btn text-light secondary-bg" @click="router.go(-1)"><i class="bi bi-caret-left-square"></i> Previous</button>
                          </div>
                          <div class="col-md-2">
                            <br/>
                            <button type="submit" class="btn text-light main-bg" dark @click="submitData"><i class="bi bi-caret-right-square"></i> Save & Continue</button>
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
         </div>
        </div>
        </div>
        </div>
        
    </div>
</template>
<style>
  .error {
    color: #ff0062;
    margin-top: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
</style>
