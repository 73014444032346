<script setup>
  import { reactive, computed, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import BaseInput from './BaseInput.vue';
  import sidemenuPage from './sidemenuPage.vue';
  import headerPage from './headerPage.vue';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength, maxLength  } from '@vuelidate/validators';
  import axios from 'axios';

  const event =  reactive({
    txtRtoNumber: '',
    loading: false,
    txtMake: '',
    txtModel:'',
    txtYear: '',
    txtEngineno: '',
    txtChasisno: '',
    cboGroup: '',
    groupList: [],
    fuelList: [],
    companyList: [],
    cboFuelType : '',
    cboCompany: ''
  });

  const rules = computed(() => {
    return {
      txtRtoNumber: { required, minLength: minLength(4) },
      txtMake: { required },
      txtModel: { required, minLength: minLength(3) },
      txtYear: { required, maxLength: maxLength(4) },
      txtEngineno: { required, minLength: minLength(5) },
      txtChasisno: { required, minLength: minLength(5) },
      cboGroup: { required },
      cboCompany: { required },
      cboFuelType: { required }
    }
  });

  const v$ = useVuelidate(rules, event);

  const router = useRouter();


  onMounted(() => {
      event.loading = true;
      const API_URL = 'combos/company/'
      axios.get(API_URL, { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
      .then((response) => {
          event.loading = false;
          event.data = response.data;
          event.groupList = event.data.vehicle_groups
          event.fuelList = event.data.fuel_types
          event.companyList = event.data.data
      }, (error) => {
          event.loading = false;
          this.error = error.response.data.message;
          alert(this.error);
      })
  });

  const submitForm = async() => {
    console.log('group value' + event.cboGroup)
    const result = await v$.value.$validate();
    if(result) {
      const data = {'crossDomain': true,
                          "vehicle_data": {
                              "company_id": event.cboCompany,
                              "vehicle_group": event.cboGroup,
                              "fuel_type": event.cboFuelType,
                              "registration_no": event.txtRtoNumber,
                              "make": event.txtMake,
                              "model": event.txtModel,
                              "year_of_manufatruring": event.txtYear,
                              "engine_no": event.txtEngineno,
                              "chasis_no": event.txtChasisno,
                              "created_by_id": localStorage.u_id
                          },
                          "vehicle_insurance_data": {
                          },
                          "vehicle_fc_data": {
                          },
                          "vehicle_gp_data": {
                          },
                          "vehicle_pc_data": {
                          },
                          "vehicle_rt_data": {}
                          }
            axios.post('vehicles/', data,
                      { headers: {"Authorization" : 'jwt ' + localStorage.jwt} })
                .then(response => {
                    localStorage.setItem('vehicleId',response.data.vehicle_id)
                    const vehicleId = response.data.vehicle_id
                    router.push(`/insurance/${vehicleId}`)
                }).catch(error => {
                    console.log(error)
                    alert('Error' + error.message)
                    return
                })
    } else {
      console.log("fields Required" + result)
    }
  };
</script>

<template>
    <div>
        <headerPage></headerPage>
        <div class="container-fluid">
            <div class="row">
               <div class="col-sm-auto bg-light sticky-top">
              <sidemenuPage></sidemenuPage>
               </div>
              <div class="col-sm p-3 min-vh-100">
        <!-- content -->
        <div class="container-fluid">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Add Truck Detail</li>
            </ol>
            </nav>
            <div class="card">
              <div class="card-body">
                <div class="container text-center">
                  <!-- Header  -->
                    <div class="row">
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-success rounded-circle">1</button>
                        <br/>
                        Vehicle
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">2</button>
                        <br/>
                        Insurance
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">3</button>
                        <br/>
                        FC Data
                      </div>
                      <div class="col-sm-12 col-md-3">
                        <button type="button" class="btn btn-secondary rounded-circle">4</button>
                        <br/>
                        Misc. Details
                      </div>
                    </div>
                    </div>
                    <div class="container-fluid">
                      <form @submit.prevent="submitForm">
                      <div class="col">
                        <hr/>
                        <p> Vehicle Details</p>
                        <div class="row">
                          <div class="col-md-12">
                            <label for="inputEmail4" class="form-label">Company</label>
                            <select class="form-select" v-model="event.cboCompany"  label="Select Group">
                              <option selected>Select Vehicle Company</option>
                              <option v-for="company, index in event.companyList" :key="index" :value="company.id">{{company.label}}</option>
                            </select>
                            <span v-for="error in v$.cboCompany.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <label for="inputEmail4" class="form-label">Group</label>
                            <select class="form-select" v-model="event.cboGroup"  label="Select Group">
                              <option selected>Select Vehicle Group</option>
                              <option v-for="group, index in event.groupList" :key="index" :value="group.id">{{group.label}}</option>
                            </select>
                            <span v-for="error in v$.cboGroup.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <label for="inputPassword4" class="form-label">Fuel Type</label>
                            <select class="form-select" v-model="event.cboFuelType" aria-label="Default select example">
                              <option selected>Select Fuel Type</option>
                              <option v-for="fuel, index in event.fuelList" :key="index" :value="fuel.id">{{fuel.label}}</option>
                            </select> 
                            <span v-for="error in v$.cboFuelType.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtRtoNumber"
                                label="RTO Number"
                                type="text"/>
                              <span v-for="error in v$.txtRtoNumber.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtMake"
                                label="Make"
                                type="text" />
                              <span v-for="error in v$.txtMake.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtModel"
                                label="Model"
                                type="text" />
                              <span v-for="error in v$.txtModel.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtYear"
                                label="Year of Manufacturing"
                                type="number" />
                              <span v-for="error in v$.txtYear.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtEngineno"
                                label="Engine Number"
                                type="text"/>
                              <span v-for="error in v$.txtEngineno.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-6">
                            <BaseInput  
                                v-model="event.txtChasisno"
                                label="Chasis Number"
                                type="text" />
                              <span v-for="error in v$.txtChasisno.$errors" :key="error.$uid" class="error">
                                {{ error.$message }}
                              </span>
                          </div>
                          <div class="col-md-10">
                            <br/>
                          </div>
                          <div class="col-md-2">
                            <br/>
                            <button type="submit"   class="btn text-light main-bg" dark @click="submitData">Save & Continue</button>
                          </div>
                        </div>
                    </div>
                    </form>
                  </div>
              </div>
            </div>
         </div>
        </div>
        </div>
        </div>
        </div>
</template>

    <style>
      .error {
        color: #ff0062;
        margin-top: 10px;
        font-size: 0.8em;
        font-weight: bold;
      }
    </style>
